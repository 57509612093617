import { graphql, navigate } from 'gatsby';
import React from 'react';

import Layout from '../components/layout';
import { Header } from '../components/page';
import Seo from '../components/seo';
import styles from '../sass/components/answer.module.scss';
import SvgArrowLeft from '../svg/arrowLeft';
import removeHtmlTags from '../utils/remove-html-tags';
import removePTags from '../utils/remove-p-tags';
import Link from '../components/link';
import Container from '../components/container';

console.log(styles);

export default class Answer extends React.Component {
  render() {
    if (typeof window !== `undefined` && !this.props.location.state) {
      const quizUrl = this.props.location.pathname.split('answer')[0]
      navigate(`${quizUrl}quiz`)
      return <Layout />
    }

    const currentLesson = this.props.data.datoCmsLesson

    const LargeLink = () => {
      const currentLessonIndex = this.props.data.allDatoCmsLesson.edges.findIndex(
        ({ node }) => node.id === currentLesson.id
      )
      const nextLesson = this.props.data.allDatoCmsLesson.edges[
        currentLessonIndex + 1
      ]

      const NextLessonLink = () => (
        <Link to={`/lessons#${nextLesson.node.url}`} className={`${styles.answer__btn} ${styles.answer__btnNext}`}>
          next lesson
        </Link>
      )

      const BackToHomepageLink = () => (
        <Link to="/lessons" className={`${styles.answer__btn} ${styles.answer__btnNext}`}>
          Back to homepage
        </Link>
      )

      return nextLesson ? <NextLessonLink /> : <BackToHomepageLink />
    }

    const answerIsRight =
      typeof window !== `undefined` && this.props.location.state.answerIsRight
    const backLink =
      typeof window !== `undefined` && this.props.location.state.backLink
    const pageData = this.props.data.datoCmsAnswerPage

    return (
      <Layout>
        <Seo
          title={
            answerIsRight
              ? removeHtmlTags(pageData.rightTitle)
              : removeHtmlTags(pageData.wrongTitle)
          }
          description={answerIsRight ? pageData.rightText : pageData.wrongText}
        />

        <section className={`${styles.answer} ${styles['answer' + (answerIsRight ? 'Success' : 'Error')]} ${styles['answer' + (this.props.data.datoCmsLesson.lessonType ? 'Secondary' : 'Primary')]}`}>
          <div className={styles.answer__inner}>
            <Header closeUrl={`/lessons#${currentLesson.url}`} data-type="answer">
              {currentLesson.number}
            </Header>

            <div className={styles.answer__content}>
              <Container>
                <h1
                  className={`growing ${styles.answer__title} ${this.props.data.datoCmsLesson.lessonType && 'font-kozel'}`}
                  dangerouslySetInnerHTML={{
                    __html: answerIsRight
                      ? removePTags(pageData.rightTitle)
                      : removePTags(pageData.wrongTitle),
                  }}
                />
                <div className={styles.answer__content__in}>
                  <div
                    className={`h6 ${styles.answer__text} ${this.props.data.datoCmsLesson.lessonType && 'font-apex'}`}
                    dangerouslySetInnerHTML={{
                      __html: answerIsRight
                        ? pageData.rightText
                        : pageData.wrongText,
                    }}
                  />
                </div>
              </Container>
            </div>

            <footer className={styles.answer__footer}>
              <Container>
                <LargeLink />

                {/* footer  link back */}
                <Link to={ backLink || `/${currentLesson.url}${answerIsRight ? '' : '/quiz'}` } className={`${styles.answer__btn} ${styles.answer__btnBack}`}>
                  <SvgArrowLeft />
                  {answerIsRight ? 'replay video' : 'try again'}
                </Link>
              </Container>
            </footer>
          </div>
        </section>
      </Layout>
    )
  }
}

export const query = graphql`
  query($id: String!) {
    datoCmsAnswerPage {
      rightTitle
      wrongTitle
      rightText
      wrongText
    }

    datoCmsLesson(id: { eq: $id }) {
      id
      number
      url
      lessonType
    }

    allDatoCmsLesson(sort: { fields: position, order: ASC }) {
      edges {
        node {
          id
          url
        }
      }
    }
  }
`
